import React from 'react'
import { useStaticQuery, graphql, Link as GLink } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Box } from 'theme-ui'
import { Layout, Stack, Main, Hero } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const styles = {
  imageWrapper: {
    overflow: `hidden`,
    position: `relative`,
    display: 'flex',
    flexWrap: 'wrap'
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  }
}



export default props => {
  const data = useStaticQuery(aboutQuery1)
  let partners2019 = []
  for (const edge of data.year2019.edges){
    partners2019.push(getImage(edge.node));
  }
  let partners2020 = []
  for (const edge of data.year2020.edges){
    partners2020.push(getImage(edge.node));
  }
  let partners2021 = []
  for (const edge of data.year2021.edges){
    partners2021.push(getImage(edge.node));
  }

  return (
    <Layout {...props}>
      <Seo title='Our partners' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            style={{
              color: `alpha`
            }}
            header='Our partners'
          />
          <Divider />
          <PageTitle
            style={{
              color: `alpha`
            }}
            subheader='2021'
          />
          <Box sx={styles.imageWrapper}>
            {partners2021.map(partner => ( <Img style={{
            borderRadius: '1rem',
            marginRight: 16,
            marginBottom: 32
          }} image={partner} />))}
          </Box>
          <Divider />
          <PageTitle
            style={{
              color: `alpha`
            }}
            subheader='2020'
          />
          <Box sx={styles.imageWrapper}>
            {partners2020.map(partner => ( <Img style={{
            borderRadius: '1rem',
            marginRight: 16,
            marginBottom: 32
          }} image={partner} />))}
          </Box>
          <Divider />
          <PageTitle
            style={{
              color: `alpha`
            }}
            subheader='2019'
          />
          <Box sx={styles.imageWrapper}>
            {partners2019.map(partner => ( <Img style={{
            borderRadius: '1rem',
            marginRight: 16,
            marginBottom: 32
          }} image={partner} />))}
          </Box>
          <Divider />
        </Main>
      </Stack>
    </Layout>
  )
}

const aboutQuery1 = graphql`
query AboutQuery1 {
  year2019: allFile(filter: {absolutePath: {regex: "./partners/2019/(.*).(jpeg|jpg|gif|png)/"}}) {
    edges {
      node {
        childImageSharp {
          gatsbyImageData(
          height: 128)
        }
      }
    }
  }

  year2020: allFile(filter: {absolutePath: {regex: "./partners/2020/(.*).(jpeg|jpg|gif|png)/"}}) {
    edges {
      node {
        childImageSharp {
          gatsbyImageData(
          height: 128)
        }
      }
    }
  }

  year2021: allFile(filter: {absolutePath: {regex: "./partners/2021/(.*).(jpeg|jpg|gif|png)/"}}) {
    edges {
      node {
        childImageSharp {
          gatsbyImageData(
          height: 128)
        }
      }
    }
  }
}
`
